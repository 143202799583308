import React from "react"

import { Box, Meter, Text } from "grommet"

const amountFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
})

const dataTableColumns = [
  {
    property: "name",
    header: <Text>Name with extra</Text>,
    primary: true,
    footer: "Total",
  },
  {
    property: "location",
    header: "Location",
  },
  {
    property: "date",
    header: "Date",
    render: datum =>
      datum.date && new Date(datum.date).toLocaleDateString("en-US"),
    align: "end",
  },
  {
    property: "percent",
    header: "Percent Complete",
    render: datum => (
      <Box pad={{ vertical: "xsmall" }}>
        <Meter
          values={[{ value: datum.percent }]}
          thickness="small"
          size="small"
        />
      </Box>
    ),
  },
  {
    property: "paid",
    header: "Paid",
    render: datum => amountFormatter.format(datum.paid / 100),
    align: "end",
    aggregate: "sum",
    footer: { aggregate: true },
  },
]

const locations = [
  "Boise",
  "Fort Collins",
  "Los Gatos",
  "Palo Alto",
  "San Francisco",
]
const data = []
for (let i = 0; i < 40; i += 1) {
  data.push({
    name: `Name ${i + 1}`,
    location: locations[i % locations.length],
    date: `2018-07-${(i % 30) + 1}`,
    percent: (i % 11) * 10,
    paid: ((i + 1) * 17) % 1000,
  })
}
const dataTable = [
  {
    name: "Alan",
    location: "",
    date: "",
    percent: 0,
    paid: 0,
  },
  {
    name: "Bryan",
    location: "Fort Collins",
    date: "2018-06-10",
    percent: 30,
    paid: 1234,
  },
  {
    name: "Chris",
    location: "Palo Alto",
    date: "2018-06-09",
    percent: 40,
    paid: 2345,
  },
  {
    name: "Eric",
    location: "Palo Alto",
    date: "2018-06-11",
    percent: 80,
    paid: 3456,
  },
  {
    name: "Doug",
    location: "Fort Collins",
    date: "2018-06-10",
    percent: 60,
    paid: 1234,
  },
  {
    name: "Jet",
    location: "Palo Alto",
    date: "2018-06-09",
    percent: 40,
    paid: 3456,
  },
  {
    name: "Michael",
    location: "Boise",
    date: "2018-06-11",
    percent: 50,
    paid: 1234,
  },
  {
    name: "Tracy",
    location: "San Francisco",
    date: "2018-06-10",
    percent: 10,
    paid: 2345,
  },
]

const groupColumns = [...dataTableColumns]
const first = groupColumns[0]
groupColumns[0] = { ...groupColumns[1] }
groupColumns[1] = { ...first }
groupColumns[0].footer = groupColumns[1].footer
delete groupColumns[1].footer

export { groupColumns, dataTable, dataTableColumns }
