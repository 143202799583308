import React from "react"
import {
  Box,
  Button,
  Paragraph,
  Heading,
  Select,
  Anchor,
  CheckBox,
} from "grommet"
import { Github, Gremlin } from "grommet-icons"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { courses } from "../constants"

const func = () => {}

export const Header = ({
  themes,
  changeBaseTheme,
  selectValue,
  reset,
  showNotification,
  diff,
  theme,
  checked,
  onChangeToggle,
}) => (
  <Box flex={false} margin={{ bottom: `` }}>
    <Box
      direction="row"
      background="light-2"
      elevation="small"
      pad={{ vertical: `xsmall`, left: `small` }}
      justify="between"
    >
      <Heading level={3}>bitsbuddy v2</Heading>
      
      <Box direction="row" align="center" gap="large">
      <Select
          id="select"
          name="select"
          placeholder="Base theme"
          size="small"
          value={selectValue}
          options={Object.keys(courses)}
        />
        <Anchor href="#budtenders" label="#retailers" />
        <Anchor href="#brands" label="#vendors" />
        <Button key="default" label="Sign Up" primary onClick={func} />
        <Button icon={<Gremlin />} key="primary"  label="Login" onClick={func} />
        
      </Box>
    </Box>
  </Box>
)
