import React from "react"
import {
  Box,
  Button,
  Paragraph,
  Heading,
  Select,
  Anchor,
  CheckBox,
} from "grommet"
import { Github, Gremlin } from "grommet-icons"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const SectionHeader = ({
  themes,
  changeBaseTheme,
  selectValue,
  reset,
  showNotification,
  diff,
  theme,
  checked,
  onChangeToggle,
}) => (
  <Box flex={false} margin={{ bottom: `large` }}>
    <Box
      direction="row"
      background="light-2"
      elevation="small"
      pad={{ vertical: `xsmall`, left: `small` }}
      justify="between"
      margin={{top:`1em`}}
    >
      <Heading level={3}>Section Header</Heading>
      <Box direction="row" align="center" gap="small">
        
      </Box>
    </Box>
  </Box>
)
