import {
  Paint,
  Action,
  Plan,
  Link,
  CheckboxSelected,
  Columns,
  Table,
  Database,
  TextAlignLeft,
  Monospace,
  Radial,
  FormEdit,
  Clock,
} from "grommet-icons"

export const SidebarSettings = {
  General: [
    {
      Icon: Table,
      area: "Settings",
      context: "global.colors",
    },
    {
      Icon: Table,
      area: "Locations",
      context: "global.colors",
    },
  ],
  Typography: [
    {
      Icon: TextAlignLeft,
      area: "All Sheets",
      context: "heading",
    },
    {
      Icon: TextAlignLeft,
      area: "Location Sheets",
      context: "paragraph",
    },
    {
      Icon: TextAlignLeft,
      area: "Inventory Overview",
      context: "paragraph",
    },
    {
      Icon: TextAlignLeft,
      area: "Product Overview",
      context: "paragraph",
    },
  ],
  Reports: [
    {
      Icon: Database,
      area: "Employee Reports",
      context: "button",
    },
    {
      Icon: Database,
      area: "Vendor Reports",
      context: "anchor",
    },
    {
      Icon: Database,
      area: "Performance Reports",
      context: "checkBox",
    },
 
  ]
  ,Tools: [
    {
      Icon: Database,
      area: "Reorder By Vendor",
      context: "button",
    },
    {
      Icon: Database,
      area: "Run Product Audits",
      context: "anchor",
    },
    {
      Icon: Database,
      area: "Run Compliance Audits",
      context: "anchor",
    },
    {
      Icon: Database,
      area: "Tasks",
      context: "checkBox",
    },
  ]
}
